<div class="separator">
  <h2 class="accent title bold-5 accent">
    {{ 'termsUse.termsUse' | translate }}
  </h2>

  <mat-dialog-content>
    <div
      class="paragraph bold-3 dark h9 line-height-22"
      [innerHTML]="'termsUse.content' | translate"
    ></div>

    <div class="block-button">
      <button mat-flat-button color="primary" class="h6 shadow" (click)="onClose()">
        {{ 'default.back' | translate }}
      </button>
    </div>
  </mat-dialog-content>
</div>
