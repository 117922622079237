import { Component } from '@angular/core';
import { PopupsService } from '@shared/popups/popups.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eng-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent {
  constructor(
    public readonly popupsService: PopupsService,
    public dialogRef: MatDialogRef<TermsOfUseComponent>,
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
