import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfUseComponent } from './terms-of-use.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
  declarations: [TermsOfUseComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule.forChild(),
    MatDialogModule,
    MatBottomSheetModule,
  ],
})
export class TermsOfUseModule {}
